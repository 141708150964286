import ApiConstants from '../constants/ApiConstant';
import axios from 'axios';
import { showAlert, TOAST_TYPE, showToast } from 'deskera-ui-library';
import Auth from './user/Auth';
import { commonCustomEvent } from './events';
import { isEmpty } from '../utilities/Common';
import { COMMON_EVENTS } from '../constants/Enum';
import AuthManager from '../managers/AuthManager';

const FAILED_REQUEST_MAX_RETRY_COUNT = 2;
const FAILED_REQUEST_RETRY_DELAY = 2000;

const ERRORS_TO_IGNORE: string[] = [];

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: ApiConstants.BASE
});

export const getCustomAxiosInstance = (config: any) => {
  const customInstance = axios.create(config);
  return customInstance;
};
const requestInterceptSuccess = (config: any) => {
  return config;
};
const requestInterceptError = (error: Error) => {
  return Promise.reject(error);
};
const responseInterceptSuccess = (response: any) => {
  AuthManager.didSessionExpired = false;
  if (response.data && response.data.code && response.data.errorMessage) {
    let message = response.data.errorMessage;
    if (
      response.data.debugMessage &&
      response.data.debugMessage.length > response.data.errorMessage.length
    ) {
      message = response.data.debugMessage;
    }
    showAlert('Error occurred!', message);
    return Promise.reject(response.data);
  }
  return response.data;
};

/**
 * @description
 * This function will be called when session is valid, but no response received from server.
 * It will retry the same request after 2seconds.
 * In case if it still fails, will show error boundary to inform user to refresh the app.
 */
const retryApiCall = async (error: any) => {
  const errorConfig = { ...error.config };

  errorConfig._retryCount = errorConfig._retryCount || 0;

  if (
    errorConfig._retryCount >= FAILED_REQUEST_MAX_RETRY_COUNT ||
    (errorConfig.method === 'post' && !errorConfig.params?._allowRetry)
  ) {
    commonCustomEvent.dispatch(COMMON_EVENTS.ERROR_OCCURRED, { detail: {} });
    return Promise.reject(error);
  }

  errorConfig._retryCount += 1;
  errorConfig._isRetryRequest = true;
  const delayRetry = new Promise((resolve) =>
    setTimeout(resolve, FAILED_REQUEST_RETRY_DELAY)
  );
  return delayRetry.then(() => axiosInstance.request(errorConfig));
};
/**
 * @description
 * Axios is currently not getting response in case of 401, 403, ERR_EMPTY_RESPONSE
 * So here we are checking if session is actually expired or not.
 */
const onEmptyResponse = async (error: any) => {
  const AuthService = Auth.getInstance();

  if (!AuthManager.didSessionExpired && !error?.config?._isRetryRequest) {
    try {
      await AuthService.checkIfUserLoggedIn();
    } catch (err) {
      AuthManager.gotoLoginPage(true);
      return Promise.reject(err);
    }
  }

  if (AuthManager.didSessionExpired) {
    return Promise.reject(error);
  }

  return retryApiCall(error);
};

let isErrorAlertVisible = false;

const responseInterceptError = (error: any) => {
  /**
   * @description - Add the extra parameter to the request to skip response interceptors
   */
  if (!!error?.config?.params?.skipInterceptor) {
    return Promise.reject(error);
  } else {
    if (isEmpty(error.response)) {
      return onEmptyResponse(error);
    } else if (
      error.response.data &&
      (error.response.data.errorCode || error.response.data.code) &&
      error.response.data.errorMessage
    ) {
      if (ERRORS_TO_IGNORE.includes(error.response.data.errorMessage))
        return Promise.reject(error.response.data);

      if (
        error.response.data.errorMessage.toLowerCase() ===
          'user not authorized' ||
        error.response.data.errorMessage.toLowerCase() === 'user not authorised'
      ) {
        showToast(error.response.data.errorMessage, TOAST_TYPE.FAILURE);
      } else {
        if (
          error.config &&
          error.config.url &&
          error.response.data.errorMessage === 'record not found'
        ) {
          // no alert required if helpcenter get
        } else {
          let message = error.response.data.errorMessage;
          if (
            error.response.data.debugMessage &&
            error.response.data.debugMessage.length >
              error.response.data.errorMessage.length
          ) {
            message = error.response.data.debugMessage;
          }

          if (isErrorAlertVisible) {
            showToast(message, TOAST_TYPE.FAILURE);
          } else {
            isErrorAlertVisible = true;
            showAlert('Error occurred!', message, [
              {
                title: 'Ok',
                className: 'bg-button text-white',
                onClick: () => (isErrorAlertVisible = false)
              }
            ]);
          }
        }
      }
      return Promise.reject(error.response.data);
    } else {
      const isRecordNotFoundError =
        error.response.status === 404 &&
        typeof error.response.data === 'string' &&
        error.response.data.toLowerCase() === 'record not found';
      const isDealPipelineNotFoundError =
        error.response.status === 400 &&
        typeof error.response.data === 'string' &&
        error.response.data === 'Unable to find pipelines';
      const isAISubscriptionPlanNotAvailable =
        error.response.status === 404 &&
        typeof error.response.data === "object" &&
        error.response.data.debugMessage.includes('AI not available in your plan');
      const isAISubscriptionRunOutOfCredits =
        error.response.status === 404 &&
        typeof error.response.data === "object" &&
        error.response.data.debugMessage.includes('Ran out of credits');
      if (
        !isErrorAlertVisible &&
        !isRecordNotFoundError &&
        !isDealPipelineNotFoundError &&
        !isAISubscriptionPlanNotAvailable && 
        !isAISubscriptionRunOutOfCredits
      ) {
        isErrorAlertVisible = true;
        showAlert(
          'Error occurred!',
          'There was some problem with server. Please try again later.',
          [
            {
              title: 'Ok',
              className: 'bg-button text-white',
              onClick: () => (isErrorAlertVisible = false)
            }
          ]
        );
      } else if((isAISubscriptionRunOutOfCredits || isAISubscriptionPlanNotAvailable) && error.config.url !== "pw-copilot-email-agent/thread/people-role-field-mapping/update") {
        isErrorAlertVisible = true;
        showAlert(
          error.response.data?.debugMessage ? error.response.data.debugMessage : 'Error occurred!',
          error.response.data?.message ? error.response.data.message : 'There was some problem with server. Please try again later.',
          [
            {
              title: 'Ok',
              className: 'bg-button text-white',
              onClick: () => (isErrorAlertVisible = false)
            }
          ]
        );
      }
      return Promise.reject(error.response);
    }
  }
};

axiosInstance.interceptors.request.use(
  (response) => requestInterceptSuccess(response),
  (error) => requestInterceptError(error)
);

axiosInstance.interceptors.response.use(
  (response) => responseInterceptSuccess(response),
  (error) => responseInterceptError(error)
);

export default axiosInstance;
