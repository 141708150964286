import http from '..';
import ApiConstants from '../../constants/ApiConstant';

export default class TenantService {
  private static _tenantInstance: TenantService;

  public static getInstance(): TenantService {
    if (!TenantService._tenantInstance) {
      TenantService._tenantInstance = new TenantService();
    }
    return TenantService._tenantInstance;
  }

  /* ***************** TENANT ***************** */
  public async getTenantDetails() {
    return http.get(ApiConstants.TENANT.DETAILS);
  }

  public async getAllTenants() {
    return http.get(ApiConstants.TENANT.GET_ALL);
  }

  /* ***************** USERS ***************** */
  public async getUsers(params?: any) {
    return http.get(ApiConstants.TENANT.USERS, { params });
  }

  /* ***************** CURRENCY ***************** */
  public async getCRMCurrencySetting() {
    return http.get(ApiConstants.TENANT.CRM_SETTING);
  }

  public async getCurrencyEnum(params: any = null) {
    if (!params)
      params = { limit: 200, page: 0, sortDir: 'ASC', sortBy: 'currencyName' };
    return http.get(ApiConstants.TENANT.GET_CURRENCY_ENUM, { params });
  }

  /* ***************** PERMISSIONS ***************** */
  public async getTenantPermissions() {
    return http.get(ApiConstants.TENANT.PERMISSIONS);
  }
  
  /* *************HAS MULTI TENANT ACCESS******** */
  public async getMultiTenantAccess(userId:any) {
    return http.get(ApiConstants.TENANT.MULTI_TENANT_ACCESS(userId));
  }

  public async getEmployeesWithoutPage() {
    
    const url = ApiConstants.TENANT.PEOPLE_USERS;
    let params = "&includeDraft=false&ignTeamData=true";
    let paramStr = '';
   
    return http
        .get(
            `${url}\?`+ params + paramStr
        )
        .catch((err: any) => {
            console.error('Error loading payrolls: ', err);
            return Promise.reject(err);
        });
}
}
